<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>

        <div class="view" style="margin-bottom: 10px; overflow: hidden;height: 500px;font-size: 18px;position: relative;">
          <img src="http://image.yabyy.com/home/zb/d3.png" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
        </div>

        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="error"-->
            <!--                effect="dark" style="margin-bottom: 20px" >-->
            <!--            </el-alert>-->
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>

                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>

                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>

                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "nvshen",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[
        {'index':0,'url':'http://image.yabyy.com/home/zb/1004975.png','id':'1004975','search':''},
        {'index':1,'url':'http://image.yabyy.com/home/zb/1007819.png','id':'1007819','search':''},

        {'index':2,'url':'http://image.yabyy.com/home/zb/1000739.png','id':'1000739','search':''},


        {'index':3,'url':'http://image.yabyy.com/home/zb/1040896.png','id':'1040896','search':''},
        {'index':4,'url':'http://image.yabyy.com/home/zb/1000728.png','id':'1000728','search':''},
        {'index':5,'url':'http://image.yabyy.com/home/zb/1013137.png','id':'1013137','search':''},
        {'index':6,'url':'http://image.yabyy.com/home/zb/1042809.png','id':'1042809','search':''},

        {'index':7,'url':'http://image.yabyy.com/home/zb/1000885.png','id':'1000885','search':''},
        {'index':8,'url':'http://image.yabyy.com/home/zb/1006835.png','id':'1006835','search':''},



        {'index':9,'url':'http://image.yabyy.com/home/zb/1046659.png','id':'1046659','search':''},
        {'index':10,'url':'http://image.yabyy.com/home/zb/1047053.png','id':'1047053','search':''},
        {'index':11,'url':'http://image.yabyy.com/home/zb/1047054.png','id':'1047054','search':''},

      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
